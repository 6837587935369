import logo from './logo.svg';
import './App.css';
//import './kidpix.css';
import { useEffect, useRef } from 'react';

import { FaTwitter, FaGithub, FaLinkedin } from 'react-icons/fa';



import bond from './bond.png';
import myco from './myco.png';
import pixels from './pixels.png';
import howarewe from './howarewe.png';
import bounties from './bounties.png';

import mural1 from './mural1.png';
import mural2 from './mural2.png';




import save from './img/kp-m_27.png';
import pencil from './img/kp-m_28.png';
import line from './img/kp-m_29.png';
import square from './img/kp-m_30.png';
import circle from './img/kp-m_31.png';
import brush from './img/kp-m_32.png';
import jumble from './img/kp-m_33.png';
import flood from './img/kp-m_34.png';
import eraser from './img/kp-m_35.png';
import alphabet from './img/kp-m_36.png';
import stamp from './img/kp-m_37.png';
import truck from './img/kp-m_38.png';
import undo from './img/kp-m_39.png';

//import init_kiddo_paint from './kidpix.js';

function App() {
  const ref = useRef(true);


  useEffect(() => {
    const firstRender = ref.current;

    if (firstRender) {
      ref.current = false;
      //init_kiddo_paint();
      console.log('init');

    } 
  })

  return (
    <div className="App">
      <div className="Profile-section-wrapper">
        <div className="Profile-section">
          <a style={{width: '75px', height: '75px'}} href="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/8112316025873927737505937898915153732580103913704334048512379743129101664257" target="__blank">
            <img className="pfp"/>
          </a>
          <div className="name-header">
          <h1 className="name">Mark Beylin</h1>
          <p>Pre-Seed Investing @ <a href="https:\/\/boost.vc" target="__blank">Boost.vc</a></p>
          </div>
          <div className="profile-icons">
            <a href="https:\/\/twitter.com/markbeylin" target="__blank"><FaTwitter className="social-icon" size={22}/></a>
            <a href="https:\/\/github.com/mbeylin" target="__blank"><FaGithub className="social-icon" size={22}/></a>
            <a href="https:\/\/linkedin.com/in/mbeylin" target="__blank"><FaLinkedin className="social-icon" size={22}/></a>
          </div>
        </div>
      </div>
      <div className="Profile-section-wrapper">
        <div className="Profile-section Bio">
          <p>I’m a Canadian crypto product builder, currently living in San Francisco, California. </p>

          <p>I grew up in Toronto, and hold a BSC in Computer Science from the <a href="https:\/\/en.wikipedia.org/wiki/University_of_Waterloo" target="__blank">University of Waterloo</a>, as well as a BBA in Business Administration from <a href="https:\/\/en.wikipedia.org/wiki/Wilfrid_Laurier_University" target="__blank">Wilfrid Laurier University</a>.</p>
          <p>I've been contributing to the Ethereum ecosystem since 2016, having worked at <a href="https://consensys.net" target="__blank">ConsenSys</a> as an early employee, and have also founded several web3 startups including <a href="https://bounties.network" target="__blank">Bounties Network</a> and <a href="https://thepixelportraits.me" target="__blank">The Pixel Portraits</a>.</p>
          <p>I’ve spent my entire professional career building products and experiences that use novel crypto primitives and intentional community design to create deep connections between organizations and the people around them.</p>
        </div>
      </div>
      <div className="Profile-section-wrapper">
        <div className="Profile-section Projects section-header">
          <div className="project-header">
            <h1 className="project-list-title"> Past Work </h1>
          </div>
        </div>
      </div>
      <div className="Profile-section-wrapper">
        <div className="project-item">
          <img className="project-image" src={bond}/>
          <div className="project-description">
            <div className="project-name">
              <div className="project-title">
                <span>Bond </span>
                <span className="project-subheader">  ·  <a href="https:\/\/onbond.co" target="__blank">onbond.co </a></span>
              </div>
              <div className="project-date">
                <span>Feb-Aug 2023</span>
              </div>
            </div>
            <span className="project-subheader project-skills">Product strategy & design  ·  Smart-contract development</span>
            <p className="project-description-text">Helping e-commerce brands launch their own mobile apps in a single click, using personalized customer loyalty <a href="https:\/\/en.wikipedia.org/wiki/Non-fungible_token" target="__blank">NFT</a>’s and <a href="https:\/\/en.wikipedia.org/wiki/Progressive_web_app" target="__blank">PWA</a>’s to deliver a hyper-targeted content experience that drives new post-purchase feedback loops. </p>
          </div>
        </div>
      </div>
      <div className="Profile-section-wrapper">
        <div className="project-item">
          <img className="project-image" src={myco}/>
          <div className="project-description">
            <div className="project-name">
              <div className="project-title">
                <span>Myco </span>
                <span className="project-subheader">  ·  <a href="https:\/\/web.archive.org\/web\/20220802025542\/https:\/\/www.myco.space\/" target="__blank">myco.space</a></span>
              </div>
              <div className="project-date">
                <span>Feb 2021-Feb 2023</span>
              </div>
            </div>
            <span className="project-subheader project-skills">
              Product strategy & design  ·  Front-end development
            </span>
            <p className="project-description-text">Helping communities earn money together online by making it easy to launch LLC's with tokenized equity, enabling profit distributions among large groups of contributors.</p>
          </div>
        </div>
      </div>
      <div className="Profile-section-wrapper">
        <div className="project-item">
          <img className="project-image" src={pixels}/>
          <div className="project-description">
            <div className="project-name">
              <div className="project-title">
                <span>The Pixel Portraits </span>
                <span className="project-subheader">  ·  <a href="https:\/\/thepixelportraits.me" target="__blank">thepixelportraits.me</a></span>
              </div>
              <div className="project-date">
                March 2021- Present
              </div>
            </div>
            <span className="project-subheader project-skills">Product strategy  ·  Smart-contract development</span>
            <p className="project-description-text">1/1 NFT portraits, custom made for you in the style of <a href="https://www.larvalabs.com/cryptopunks" target="__blank">Cryptopunks</a>. Introduced a new auction queue mechanism, allowing for continuous free-market price discovery to help artists earn a higher upside on their work.</p>
            <p className="project-description-text">The longest running 10k pfp collection mint on Ethereum.</p>
          </div>
        </div>
      </div>
      <div className="Profile-section-wrapper">

        <div className="project-item">
          <img className="project-image" src={howarewe} />
          <div className="project-description">
            <div className="project-name">
              <div className="project-title">
                <span>HOW ARE WE </span>
                <span className="project-subheader">  ·  <a href="https:\/\/howarewe.xyz" target="__blank">howarewe.xyz</a></span>
              </div>
              <div className="project-date">
                June 2020
              </div>
            </div>
            <span className="project-subheader project-skills">Product strategy  ·  Smart-contract development</span>
            <p className="project-description-text">The first performance art NFT ever deployed on Ethereum, created during the peak of COVID lockdowns in 2020 by 20+ artists, writers, developers and lawyers who collaborated to create a shared film project and time capsule. Together they told a story of what it truly felt like to be locked up in our houses for those first few months. Commissioned by the Onassis Foundation, with proceeds distributed to contributors using tokenized contribution rewards. </p>
          </div>
        </div>
      </div>


      <div className="Profile-section-wrapper">

        <div className="project-item">
          <img className="project-image" src={bounties} />
          <div className="project-description">
            <div className="project-name">
              <div className="project-title">
                <span>Bounties Network</span>
                <span className="project-subheader">  ·  <a href="https://web.archive.org/web/20181129005033/https://explorer.bounties.network/" target="__blank">bounties.network</a></span>
              </div>
              <div className="project-date">
                Dec 2016-Jan 2020
              </div>
            </div>
            <span className="project-subheader project-skills">Product design  ·  Front-end development  ·  Smart-contract development</span>
            <p className="project-description-text">An open protocol for the outsourcing of tasks by token-powered organizations to incentivize community contributions. Supported platforms like <a href="https:\/\/gitcoin.co" target="__blank">Gitcoin</a> in delivering their on-chain bounties, and grew the protocol to $1m+ in TVL (at least a year before the TVL metric even existed).</p>
          </div>
        </div>
      </div>

      <div className="Profile-section-wrapper">
        <div className="Profile-section section-header">
          <div className="project-header Writing">
            <h1 className="project-list-title"> Selected Writing </h1>

            <p><a href="https://m.mirror.xyz/zMwLQ-x8cYTmP6VbvK1hSK-Rbdts6tqgDP23FrFhYRA" target="__blank">Introducing Incentives Into Communities</a><br/>
            <i> Apr 5, 2022 - Myco Blog</i></p>

            <p><a href="https://m.mirror.xyz/Eb308XiSttfLIWWMq5tVZd6xswtUbVjEGngEBHezAEE" target="__blank">Dilutive DAOs: Building a Community in Perpetuity</a><br/>
            <i> Feb 15, 2022 - Myco Blog</i></p>

            <p><a href="https://m.mirror.xyz/OOvCWNZi1_xQcPC7AMaUiqrKVz4jTsKqS1okDtNuJPI" target="__blank">Building Community, One Face at a Time (Part 2): The Pixel Portraits Story</a><br/>
            <i> Sept 14, 2021 - Myco Blog</i></p>

            <p><a href="https://m.mirror.xyz/Xr0TX6_b6TSB5Ui90mvfixrlfp2fgmhOtzQbM3CXegk" target="__blank">Building Community, One Face at a Time (Part 1)</a><br/>
            <i> Sept 14, 2021 - Myco Blog</i></p>
            
            <p><a href="https://m.mirror.xyz/6UxMOPMAnCZr8XSkhIM2Xx47bZ7X5rColwpnwIDbx5s" target="__blank">The Myco Blueprint</a><br/>
            <i> Sept 12, 2021 - Myco Blog</i></p>

            <p><a href="https://m.mirror.xyz/OlcHFb-umaeoQrmSroGMjRKc2mp2Vm898CBUo0CMHeY" target="__blank">Who Owns Your Metaverse?</a><br/>
            <i> Aug 17, 2021 - Myco Blog</i></p>

            <p><a href="https://m.mirror.xyz/HNRZ7nbcR-lMpmLfYLbNysDs8ubVXB3FTitMyF6dlNY" target="__blank">How to Mix Business and Friendship</a><br/>
            <i> May 13, 2021 - Myco Blog</i></p>

            <p><a href="https://medium.com/bounties-network/tcrs-2-0-self-curating-lists-81447576d5e5" target="__blank">TCRs 2.0: Self Curating Lists</a><br/>
            <i> Feb 25, 2019 - Bounties Network Blog</i></p>
            
            <p><a href="https://medium.com/bounties-network/bounties-for-the-oceans-incentives-to-change-the-world-8f3429fd01e9" target="__blank">Bounties For The Oceans: Incentives to Change the World</a><br/>
            <i> Dec 10, 2018 - Bounties Network Blog</i></p>

            <p><a href="https://medium.com/bounties-network/the-importance-of-standards-for-bounties-on-ethereum-93b518d14f9c" target="__blank">The Importance of Standards for Bounties on Ethereum</a><br/>
            <i> Jan 30, 2018 - Bounties Network Blog</i></p>
          </div>
        </div>
      </div>


      <div className="Profile-section-wrapper">
        <div className="Profile-section section-header">
          <div className="project-header Interests">
            <h1 className="project-list-title"> Personal Interests </h1>
              <div className="Bio">
                <p>I like to play sports, listen to instrumental music, and patronize interesting arts experiences. <br/><br/>
                I grew up playing soccer and waterpolo, swimming, running track, and even went to school for <a href="https://www.nbs-enb.ca/" target="__blank">classical ballet</a>. These days I run, ski, go rock climbing.
                <br/><br/>
                I also like to make art in my free time, using spray paint and other mediums</p>
                <div className="art-wrapper">                
                  <img className="art" src={mural1}/>
                  <img className="art" src={mural2}/>
                </div>

              </div>  
          </div>
        </div>
      </div>

      <div className="Profile-section-wrapper">
        <div className="Profile-section Principles">
          <h1 className="project-list-title"> Principles to Live By</h1>
            <p><i> Originally written in Jan 2020, after deciding to shut down my first company. 
            <br/>
            Occasional revisions made since.</i></p>
          <h2>1. Love</h2>
          <p>Love every person and thing around you</p>
          <p>Love trumps hate, it always prevails eventually</p>
          <p>Loving other people begins with loving yourself</p>
          <p>Take care of the people you love, show them love through your actions and your words</p>
          <p>Love grows things, hate shrinks them</p>
          <p>Give the gift of tough love only to those who can appreciate it</p>
          <p>Love even and especially those who have no love for you</p>
          <p>Forgive yourself and others for their wrongdoings - what matters is how you move forward</p>
          <p>Don't be too hard on yourself or other people - we are but imperfect beings aspiring towards perfection</p>
          <h2>3. Move with integrity</h2>
          <p>There's never an okay time to sacrifice your integrity</p>
          <p>Be honest, with yourself and with everyone around you</p>
          <p>When you commit to something, hold yourself to following through on it</p>
          <p>Don't make commitments you can't keep (with yourself, or others)</p>
          <p>If you've [accidentally] made two mutually exclusive commitments, you're bound to follow through on the first one (and then rectify the situation with the second one)</p>
          <p>Everything great is built on trust</p>
          <p>Keep your conscience clear</p>
          <h2>4. Listen</h2>
          <p>Listen more than you speak in conversation</p>
          <p>Listen to the signals that the world around you is sending you in the present moment</p>
          <p>Listen deeply and actively without letting your mind wander</p>
          <h2>5. Apply yourself</h2>
          <p>In whatever you're pursuing, never pursue it half-heartedly</p>
          <p>Be proud of the work you produce, don't settle for less than your best (but don't be a perfectionist either)</p>
          <p>Hold yourself to higher standards than the ones you're held to</p>
          <p>Hard work is that which occurs at the edges of your capabilities. Don't fear the discomfort you'll experience there</p>
          <p>Starting things is easy, finishing them is hard</p>
          <p>Grace is the result of mastery, mastery is the result of hard work and grit</p>
          <h2>6. Grow</h2>
          <p>Seek opportunities to improve your mind through education, reading, and writing</p>
          <p>Seek opportunities to improve your body through exercise and training</p>
          <p>Seek opportunities to improve your existence by iterating on your habits</p>
          <p>Seek out people who make you a better version of yourself; avoid people who do the opposite</p>
          <p>Seek out feedback, from those who can see your blind spots clearly</p>
          <h2>7. Be open minded to new ideas, but stay skeptical</h2>
          <p>Actively look for ideas and people who claim to disprove your beliefs</p>
          <p>Never believe things just because they're written, no matter how factual the source may appear</p>
          <p>"Verify, don't trust"</p>
          <h2>8. Focus</h2>
          <p>Focus on the moment that you're in</p>
          <p>Don't wander when walking along a path- stick to the endeavour you started until it reaches its natural end</p>
          <p>Don't take on too many endeavours simultaneously, you won't accomplish any of them to a degree you're satisfied with</p>
          <h2>9. Watch your thoughts</h2>
          <p>Understand the way you think, learn to adjust for your own biases</p>
          <p>Listen to your subconscious mind and the signals it pops into your brain</p>
          <p>Choose your thoughts wisely, they make up your world</p>
          <p>Your thoughts and projections will teach you about your insecurities</p>
          <p>"The unexamined parts of ourselves are running around masquerading as the things we think we hate about other people"</p>
          <h2>10. Follow your intuition</h2>
          <p>Follow your gut instinct on the people, situations, and choices in front of you</p>
          <p>Follow the voice in your head that tells you to pursue something, even [and especially] when you can't rationally explain why it's the right course of action</p>
          <p>Pay attention when situations or people feel off, don't ignore the feeling</p>
          <h2>11. Be firm but kind</h2>
          <p>"Peace over war, but war over injustice"</p>
          <p>Most conflict stems from poor communication</p>
          <h2>12. "Trust in the Universe...</h2>
          <p>Trust in the world, even when it isn't clear that things will work out</p>
          <p>Don't get nervous because your view of time is only a sliver</p>
          <p>Persist in moments of uncertainty</p>
          <p>Create spaces for serendipity to occur for yourself and for others</p>
          <h2>13. ...But also tether your camel"</h2>
          <p>Be thrifty, spend less than you earn</p>
          <p>Make sure your livelihood is taken care of, that your responsibilities are not overlooked</p>
          <p>Take care of your body, and the physical spaces which you inhabit</p>
          <p>Proactive health > reactive health</p>
          <p>Avoid overindulgence in the things you enjoy</p>
          <p>The difference between medicine and poison is dosage</p>
          <p>"You don't have to worry about getting ready if you stay ready"</p>
          <h2>14. Don't forget to Laugh</h2>
          <p>At yourself, and at the past</p>
          <p>At the universe, and the absurdity of life</p>
          <p>With people, not at them</p>
          <h2>15. Start with yourself</h2>
          <p>Focus on what you can control, ignore the things outside of that</p>
          <p>Give constructive feedback to yourself before giving it to others</p>
          <h2>16. Leave the world a better place than when you got here</h2>
          <p>Everyone is born with a unique set of gifts - figure yours out and share them with the world</p>
          <p>Be generous with your community</p>
          <p>Stand on the shoulders of giants, welcome others when they stand on yours</p>
          <p>Don't expect anything in return for being a good person, including recognition</p>
          <p>Answer questions when people ask</p>
          <h2>17. Be humble, be proud, be brutally honest</h2>
          <p>Maintain a realistic knowledge about your circle of competence</p>
          <p>Never overstate your qualifications - underpromise and overdeliver</p>
          <p>Never be too showy about your successes</p>
          <p>Your judgements of yourself matter infinitely more than other people's judgements of you</p>
          <p>Quiet your critical mind while creating new things, invite it back into the room once you're finished</p>
          <h2>18. Seek balance</h2>
          <p>External balance begins with internal balance</p>
          <p>Everything has its opposite; learn to identify and integrate them</p>
          <p>Everything exists on a spectrum, rarely are things discretely binary</p>
          <p>Avoid false dichotomies</p>
          <p>Seek opposite sides of all perspectives- especially your own</p>
          <p>Flow with the stream, and not against it</p>
          <h2>2. Don't take yourself too seriously</h2>
          <p>Don't take life too seriously</p>
          <p>Have fun, enjoy the ride</p>
          <p>All of the high scores you think matter don't actually matter at all</p>
          <h2>☺</h2>
        </div>
      </div>


      <div className="Profile-section-wrapper">
        <div className="Profile-section section-header">
          <div className="project-header Principles">
            <h1 className="project-list-title"> Books I Return To</h1>
            <p><i>
            <b>Human Accomplishment </b>- Charles Murray <br/><br/>
            <b>Operating Manual for Spaceship Earth </b>- Buckminster Fuller<br/><br/>
            <b>Fragments of an Anarchist Anthropology </b>- David Graeber<br/><br/>
            <b>Faust </b>- Johann Wolfgang von Goethe<br/><br/>
            <b>The Art of Shakespeare’s Sonnets </b>- Helen Vendler<br/><br/>
            <b>Essential Writings </b>- Ralph Waldo Emerson<br/><br/>
            <b>Finite and Infinite Games </b>- James P Carse<br/><br/>
            <b>The Compound Effect </b>- Darren Hardy<br/><br/>
            <b>The 7 Habits of Highly Effective People </b>- Stephen Covey<br/><br/>
            <b>The Art of War </b>- Sun Tzu<br/><br/>
            <b>How to Win Friends and Influence People </b>- Dale Carnegie<br/><br/>
            <b>The 48 Laws of Power </b>- Robert Green<br/><br/>
            <b>The Autobiography of Benjamin Franklin </b>- Benjamin Franklin <br/><br/>
            <b>Zero to One </b>- Peter Thiel<br/><br/>
            <b>The Outsiders: Eight Unconventional CEOs and their Radically Rational Blueprint for Success </b>- William N. Thorndike Jr.<br/><br/>
            <b>Meditations </b>- Marcus Aurelius<br/><br/>
            <b>A Man’s Search for Meaning </b>- Viktor Frankl<br/><br/>
            <b>Pedagogy of the Oppressed </b>- Paulo Friere<br/><br/>
            <b>Memories, Dreams, Reflections </b>- Carl Jung<br/><br/>
            <b>Godel, Escher, Bach: an Eternal Golden Braid </b>- Douglas Hofstadter<br/><br/>
            <b>The Power of Now </b>- Eckhart Tolle<br/><br/>
            <b>The Sufi Book of Life </b>- Neil Douglas-Klotz<br/><br/>
            <b>The Prophet </b>- Khalil Gibran<br/><br/>
            <b>Siddhartha </b>- Herman Hesse<br/><br/>
            <b>Masnavi </b>- Rumi<br/><br/>
            <b>The Conference of the Birds </b>- Farid ud-Din Attar<br/><br/>
            <b>The Tao of Pooh </b>- Benjamin Hoff<br/><br/>
            <b>Tao Te Ching </b>- Lao Tzu<br/><br/>
            <b>In Search of the Miraculous </b>- P. D. Ouspensky<br/><br/>
            <b>Devotions </b>- Mary Oliver<br/><br/></i>
            </p>
          </div>
        </div>
      </div>

      <div className="Profile-section-wrapper">
        <div className="Profile-section section-header">
          <div className="project-header">
          </div>
        </div>
      </div>


    </div>
  );
}

export default App;

